import type {CropData} from '@Panels/cropper-panel';

export enum CoverSource {
  UPLOAD = 'UPLOAD',
  DESIGN = 'DESIGN',
}

export interface SocialPostCoverParams {
  source: CoverSource;
  uploadedCoverPostParams?: {
    uploadImageHashedFileName: string;
    uploadImageFileExtension: string;
    cropData: CropData;
  };
  timestampedCoverParams?: {
    graphicUrl: string;
    timestamp: number;
    idAccount: number;
    idPost: string;
    idDesign: string;
  };
}

export const getFlattenedSocialPostCoverParams = (params: SocialPostCoverParams): Record<string, any> => {
  const flatParams: Record<string, any> = {
    source: params.source,
  };

  if (params.uploadedCoverPostParams) {
    flatParams.uploadImageHashedFileName = params.uploadedCoverPostParams.uploadImageHashedFileName;
    flatParams.uploadImageFileExtension = params.uploadedCoverPostParams.uploadImageFileExtension;

    const {cropData} = params.uploadedCoverPostParams;

    flatParams.x = cropData.x;
    flatParams.y = cropData.y;
    flatParams.width = cropData.width;
    flatParams.height = cropData.height;
    flatParams.imageWidth = cropData.imageWidth;
    flatParams.imageHeight = cropData.imageHeight;
  }

  if (params.timestampedCoverParams) {
    flatParams.graphicUrl = params.timestampedCoverParams.graphicUrl;
    flatParams.timestamp = params.timestampedCoverParams.timestamp;
    flatParams.idAccount = params.timestampedCoverParams.idAccount;
    flatParams.idPost = params.timestampedCoverParams.idPost;
    flatParams.idDesign = params.timestampedCoverParams.idDesign;
  }

  return flatParams;
};
