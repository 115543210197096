export enum GalleryThemeURI {
  DEFAULT = 'posters/gallery',
  CHRISTMAS = 'g/christmas-posters',
  // Add more /g/ URIs here as needed
}

export enum GallerySizeURI {
  SOCIAL_MEDIA = 'sizes/social-media-templates/all/all',
  POSTERS = 'sizes/posters/all/all',
  // Add more /sizes/ URIs here as needed
}

export type GalleryPageURI = GallerySizeURI | GalleryThemeURI;
